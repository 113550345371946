<script>
    import { fly } from "svelte/transition";

    import ContentWrapper from "../../layout/componentWrapper/componentWrapper.svelte";
    import Mockup from "../../UI/mockupMobile/mockup.svelte"

    import newIntersectionObserver from "helpers/intersectionObserver";
    import { labels } from "content";

    let intersecting;

    const intersectionListener = newIntersectionObserver(() => intersecting = true, "-150px");

    // Transitions
    const trans1 = { y: 100, duration: 950, delay: 450 };
    const trans2 = { y: 100, duration: 1200, delay: 500 };
</script>

<style lang="scss">article {
  width: 100%;
  margin: 5rem 0 0 0; }

section {
  background-color: white;
  -webkit-transition: background-color 1s;
  transition: background-color 1s;
  overflow: hidden; }

h1 {
  margin: 0 auto; }

p {
  color: white; }

h3 {
  margin-bottom: 2rem;
  font-family: 'Inria Serif', serif;
  font-weight: 400;
  color: white; }

h4 {
  color: white; }

.intersecting {
  background-color: #14181e; }

.mockup {
  width: 100%;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
          justify-content: center;
  -webkit-box-align: center;
          align-items: center; }

@media screen and (min-width: 768px) {
  article {
    width: 60%;
    margin: auto 0 auto 4rem; }
  .mockup {
    width: 40%; } }
/*# sourceMappingURL=src/components/sections/mobile/mobile.svelte.map */</style>

<section use:intersectionListener class:intersecting class="top-clip bottom-clip">
    <ContentWrapper>
        {#if intersecting}
            <div class="mockup">
                <Mockup/>
            </div>

            <article>
                <h3 transition:fly="{trans1}">{labels.payments.name}</h3>
                <p transition:fly="{trans2}">{labels.payments.caption}</p>
                <p transition:fly="{trans2}">{labels.payments.caption2}</p>
            </article>
        {/if}
    </ContentWrapper>
</section>