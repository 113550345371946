<script>
    import { fly } from "svelte/transition";

    import ContentWrapper from "../../layout/componentWrapper/componentWrapper.svelte";

    import createIntersectionObserver from "helpers/intersectionObserver";
    import { labels } from "content";

    let intersecting;

    const intersectionListener = createIntersectionObserver(() => intersecting = true, "-150px");

    // Transitions
    const trans1 = { y: 70, duration: 750, delay: 250 };
</script>

<style lang="scss">article {
  width: 100%;
  height: 100%;
  margin: 1.5rem 0 0 0; }
  article p {
    margin-bottom: 2rem; }

.bubbles {
  display: none;
  width: 50%;
  height: 100%; }

section {
  overflow-x: visible; }

.bubble, .react, .svelte, .sass, .wordpress {
  width: 10rem;
  height: 10rem;
  background-color: white;
  -webkit-box-pack: center;
          justify-content: center;
  -webkit-box-align: center;
          align-items: center;
  display: -webkit-box;
  display: flex;
  flex-shrink: 0;
  border-radius: 55%;
  -webkit-animation: float 1.8s alternate ease-in-out infinite;
          animation: float 1.8s alternate ease-in-out infinite;
  overflow: hidden; }
  .bubble img, .react img, .svelte img, .sass img, .wordpress img {
    width: 90%;
    height: auto; }

.react {
  width: 12rem;
  height: 12rem;
  margin: 0 0 0 13rem;
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s; }

.svelte {
  width: 10rem;
  height: 10rem;
  margin: 0 0 0 25rem;
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s; }
  .svelte img {
    width: 70%; }

.sass {
  width: 11rem;
  height: 11rem;
  margin: 0 0 0 7rem;
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s; }
  .sass img {
    width: 65%; }

.wordpress {
  width: 10.5rem;
  height: 10.5rem;
  display: -webkit-box;
  display: flex;
  margin: 0 0 0 22rem;
  -webkit-animation-delay: 0.35s;
          animation-delay: 0.35s; }

.banner {
  width: 100%;
  height: 3.5rem;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
          align-items: center; }
  .banner img {
    width: auto;
    height: 100%; }

@media screen and (min-width: 768px) {
  article {
    width: 50%;
    margin: auto 5rem auto 4rem; }
  .bubbles {
    display: block; }
  .banner {
    display: none; } }
/*# sourceMappingURL=src/components/sections/frontend/frontend.svelte.map */</style>

<section use:intersectionListener>
    <ContentWrapper disableMarginTop smallMarginBottom>
        {#if intersecting}
            <div class="bubbles" transition:fly="{trans1}">
                <div class="react">
                    <img src="/assets/logos/reactjs.svg" alt="react"/>
                </div>
                <div class="svelte">
                    <img src="/assets/logos/svelte.png" alt="svelte"/>
                </div>
                <div class="sass">
                    <img src="/assets/logos/sass.svg" alt="sass"/>
                </div>
                <div class="wordpress">
                    <img src="assets/logos/wordpress.svg" alt="wordpress"/>
                </div>
            </div>

            <article transition:fly="{trans1}">
                <h4>{labels.stack.frontendTitle}</h4>
                <p>{labels.stack.frontendText}</p>
                <div class="banner">
                    <img src="/assets/logos/reactjs.svg" alt="react"/>
                    <img src="/assets/logos/sass.svg" alt="sass"/>
                    <img src="/assets/logos/svelte.png" alt="svelte"/>
                    <img src="assets/logos/wordpress.svg" alt="wordpress"/>
                </div>
            </article>
        {/if}
    </ContentWrapper>
</section>