<script>
    import { fly } from "svelte/transition";

    export let intersecting;

    // Transitions
    const t1 = { y: 70, duration: 1000, delay: 250 };
</script>

<style lang="scss">.mockup {
  position: relative;
  max-width: 310px;
  height: 250px;
  margin: 0 auto;
  border-radius: 10px;
  box-shadow: 35px 50px 90px -25px rgba(50, 50, 95, 0.5), 20px 35px 75px -35px rgba(0, 0, 0, 0.5);
  overflow: hidden;
  -webkit-animation: animate 1.5s forwards;
          animation: animate 1.5s forwards; }

.browser {
  position: relative;
  width: 100%;
  height: 30px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  background: -webkit-gradient(linear, left bottom, left top, color-stop(20%, #383a3c), to(#444649));
  background: linear-gradient(0deg, #383a3c 20%, #444649 100%); }

.controls {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center; }
  .controls div {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-left: 10px;
    background-color: #ff463a; }
    .controls div:nth-child(2) {
      background-color: #ffd70a; }
    .controls div:nth-child(3) {
      background-color: #32d64b; }

.bar {
  width: 55%;
  height: 55%;
  margin-left: 90px;
  border-radius: 40px;
  background-color: #585b60; }

.content {
  width: 100%;
  height: calc(100% - 30px);
  background-color: white;
  padding: 20px; }

.modules {
  position: relative;
  width: 100%;
  height: 80px;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
          align-items: center;
  border-radius: 7px;
  background-color: white;
  box-shadow: 0 5px 8px rgba(0, 30, 85, 0.12), 0 1px 3px rgba(0, 30, 85, 0.08); }

.circle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: 0 15px;
  background: url("/assets/logos/shopify.svg") no-repeat center;
  background-size: 80%;
  background-color: white;
  z-index: 2; }

.circle:last-of-type {
  background: url("/assets/logos/wordpress.svg") no-repeat center;
  background-size: 45%;
  background-color: white; }

.lining {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 80%;
  height: 2px;
  background-color: white;
  margin: auto;
  z-index: 1; }

.mapping {
  width: 100%;
  height: calc(100% - 100px);
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
          justify-content: space-between;
  margin-top: 20px; }
  .mapping > div {
    width: calc(100% / 3);
    height: 70%;
    display: block;
    padding: 10px;
    border-radius: 7px;
    background-color: white;
    box-shadow: 0 5px 8px rgba(0, 30, 85, 0.12), 0 1px 3px rgba(0, 30, 85, 0.08); }
    .mapping > div span {
      position: relative;
      display: inline-block;
      width: 20px;
      height: 10px;
      float: left;
      border-radius: 20px;
      background-color: #edeff2;
      margin: 0 5px 5px 0; }
  .mapping > div:nth-child(2) {
    height: 100%;
    margin: 0 20px; }
    .mapping > div:nth-child(2) span {
      width: 80%;
      display: block;
      margin: 0 auto 5px auto;
      float: none; }

@-webkit-keyframes animate {
  to {
    -webkit-transform: rotate3d(0.34, -0.95, 0, 20deg) rotateZ(7deg);
            transform: rotate3d(0.34, -0.95, 0, 20deg) rotateZ(7deg); } }

@keyframes animate {
  to {
    -webkit-transform: rotate3d(0.34, -0.95, 0, 20deg) rotateZ(7deg);
            transform: rotate3d(0.34, -0.95, 0, 20deg) rotateZ(7deg); } }

@media screen and (min-width: 768px) {
  .mockup {
    max-width: 470px;
    height: 320px; } }
/*# sourceMappingURL=src/components/UI/mockupWeb/mockupWeb.svelte.map */</style>

{#if intersecting}
    <div class="mockup" class:animate={intersecting} transition:fly="{t1}">

        <div class="browser">
            <div class="controls">
                <div></div>
                <div></div>
                <div></div>
            </div>

            <div class="bar"></div>
        </div>

        <div class="content">
            <div class="modules">
                <div class="circle"></div>
                <div class="lining"></div>
                <div class="circle"></div>
            </div>

            <div class="mapping">
                <div>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>

                <div>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>

                <div>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
        </div>

    </div>
{/if}