<style lang="scss">.wrapper {
  width: 100%;
  height: 8rem;
  display: -webkit-box;
  display: flex; }
  .wrapper > div:last-child {
    display: none; }

.integration {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: flex;
  border-radius: 10px;
  box-shadow: 0 5px 8px rgba(0, 30, 85, 0.12), 0 1px 3px rgba(0, 30, 85, 0.08); }

.logos {
  width: 9rem;
  height: 100%;
  padding: 1rem;
  flex-shrink: 0; }

.logo, .slack, .restEndpoint {
  width: 5rem;
  height: 5rem;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
          justify-content: center;
  -webkit-box-align: center;
          align-items: center;
  flex-shrink: 0;
  border-radius: 50%;
  background-color: white;
  -webkit-transform: translate(0, -4rem);
          transform: translate(0, -4rem); }
  .logo img, .slack img, .restEndpoint img {
    width: 70%;
    height: auto; }
  .logo:first-of-type, .slack:first-of-type, .restEndpoint:first-of-type {
    -webkit-transform: translate(2rem, 0);
            transform: translate(2rem, 0); }

.slack {
  background-color: white; }
  .slack img {
    width: 55%; }

.restEndpoint {
  background-color: #2574fc;
  font-size: 10px;
  font-weight: bold;
  color: white; }

.meta {
  width: 100%;
  height: 100%;
  padding: 1.5rem 0 0 2rem; }
  .meta div {
    width: 80%;
    height: 1rem;
    margin-bottom: 1rem;
    border-radius: 25px;
    background-color: #edeff2; }
  .meta div:nth-child(2) {
    width: 50%; }
  .meta div:nth-child(3) {
    width: 20%; }

@media screen and (min-width: 768px) {
  .wrapper > div:last-child {
    display: -webkit-box;
    display: flex; }
  .integration {
    width: 50%; }
    .integration:first-of-type {
      margin-right: 2rem; } }
/*# sourceMappingURL=src/components/UI/mockupDashboard/integrations/integrations.svelte.map */</style>

<div class="wrapper">

    <div class="integration">
        <div class="logos">
            <div class="logo">
                <img src="/assets/logos/shopify.svg" alt="shopify"/>
            </div>

            <div class="slack">
                <img src="/assets/logos/wordpress.svg" alt="shopify"/>
            </div>
        </div>

        <div class="meta">
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>

    <div class="integration">
        <div class="logos">
            <div class="restEndpoint">
                <p>{"{ REST }"}</p>
            </div>

            <div class="logo">
                <img src="/assets/logos/shopify.svg" alt="shopify"/>
            </div>
        </div>

        <div class="meta">
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>

</div>