<script>
    import scrollHandler from "helpers/scrollHandler";
    import { labels } from "content";

    export let workAnchor;
    export let aboutAnchor;

    let yOffset = 0;
    let translate;
    let previousScrollPosition = yOffset;

    const handleScroll = () => {
        if (Math.abs(yOffset - previousScrollPosition) > 100) {
            translate = yOffset < previousScrollPosition ? "0" : "-100%";
            previousScrollPosition = yOffset;
        }
    };

    window.onscroll = handleScroll;
</script>

<svelte:window bind:scrollY={yOffset}/>

<style lang="scss">nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 8rem;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
          justify-content: center;
  -webkit-box-align: center;
          align-items: center;
  background-color: rgba(20, 24, 30, 0.95);
  -webkit-transition: -webkit-transform 0.5s;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
  color: white;
  z-index: 99; }
  @supports ((-webkit-backdrop-filter: blur(20px)) or (backdrop-filter: blur(20px))) or (--webkit-backdrop-filter: blur(20px)) {
    nav {
      background-color: rgba(20, 24, 30, 0.85);
      -webkit-backdrop-filter: saturate(180%) blur(20px);
              backdrop-filter: saturate(180%) blur(20px); } }

.logo {
  height: 2rem; }

.wrapper {
  width: 100%;
  max-width: 1250px;
  height: 100%;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
          align-items: center;
  margin: 0 2rem;
  box-sizing: border-box;
  background: url(/assets/images/feature_mobile_2-2.png);
  background-size: 0;
  font-size: 2rem; }
  .wrapper ul {
    display: -webkit-box;
    display: flex; }
  .wrapper li {
    margin: 0 1rem;
    cursor: pointer; }

@media screen and (min-width: 768px) {
  nav {
    height: 10.5rem; }
  .wrapper {
    margin: 0 4rem;
    font-size: 2.5rem; } }
/*# sourceMappingURL=src/components/layout/navbar/navbar.svelte.map */</style>

<nav style="transform: translateY({translate});">
    <div class="wrapper">
        <span> <img  class ="logo" src="/assets/images/devsonic-logo.png" alt="logo">
            <b>DevSonic</b>
        </span>
        <ul>
            <li on:click={() => scrollHandler(workAnchor)}>{labels.nav.work}</li>
            <li on:click={() => scrollHandler(aboutAnchor)}>{labels.nav.about}</li>
        </ul>
    </div>
</nav>