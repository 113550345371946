<script>
    import { onMount } from 'svelte';
    import ContentWrapper from "../../layout/componentWrapper/componentWrapper.svelte";
    import SocialButtons from "../../UI/socialButtons/socialButtons.svelte";
    import { labels } from "content";

    let yOffset = 0;

    let phrase = "We Are DevSonic.";
    let typedChars = "";
    let index = 0;
    let typeWriter;

    const typeChar = () =>{
        if(index < phrase.length){
            typedChars += phrase[index];
            index += 1;
        }else{
            clearInterval(typeWriter);
        }
    }

    const typing = () => typeWriter = setInterval(typeChar,100);

    onMount(() =>{
        typing();
    });
</script>

<svelte:window bind:scrollY={yOffset}/>

<style lang="scss">section {
  width: 100%;
  height: calc(100vh + 4rem);
  padding-top: 70px;
  background-color: #14181e;
  color: white;
  overflow: hidden; }

.intro {
  width: 100%;
  margin-top: 2rem; }
  .intro h1 {
    font-size: 4.5rem;
    -webkit-animation: fadein 1.5s backwards ease-out;
            animation: fadein 1.5s backwards ease-out; }
  .intro p {
    margin-bottom: 2.5rem;
    font-weight: 500;
    -webkit-animation: fadein 1.2s 0.4s backwards ease-out;
            animation: fadein 1.2s 0.4s backwards ease-out; }
  .intro div {
    -webkit-animation: fadein 1s 0.6s backwards ease-out;
            animation: fadein 1s 0.6s backwards ease-out; }

@media screen and (min-width: 768px) {
  .intro {
    width: 55%; }
    .intro h1 {
      font-size: 7rem; } }

@-webkit-keyframes fadein {
  from {
    opacity: 0;
    -webkit-transform: translateY(50px);
            transform: translateY(50px); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@keyframes fadein {
  from {
    opacity: 0;
    -webkit-transform: translateY(50px);
            transform: translateY(50px); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); } }
/*# sourceMappingURL=src/components/sections/header/header.svelte.map */</style>

<section class="bottom-clip">
    <ContentWrapper disableFlex>
        <div class="intro" style="transform: translate(0,{-yOffset * 2 / 4}px);">
            <h2>{labels.header.greeting}</h2>
            <h2>{typedChars}</h2>
            <p>{labels.header.intro}</p>
            <div>
                <SocialButtons/>
            </div>
            
        </div>
    </ContentWrapper>
</section>