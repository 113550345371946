<script>
    import { fly } from "svelte/transition";

    import ContentWrapper from "../../layout/componentWrapper/componentWrapper.svelte";
    import MockupDashboard from "../../UI/mockupDashboard/mockupDashboard.svelte";
    import MockupWeb from "../../UI/mockupWeb/mockupWeb.svelte";

    import newIntersectionObserver from "helpers/intersectionObserver";
    import { labels } from "content";

    let intersecting;

    const intersectionListener = newIntersectionObserver(() => intersecting = true, "-150px");

    // Transitions
    const trasn1 = { y: 70, duration: 1000, delay: 250 };
    const trans2 = { y: 70, duration: 1000, delay: 350 };
</script>

<style lang="scss">section {
  padding: 2rem 2rem 0 2rem;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  border-bottom: 1px solid #edeff2; }

article {
  width: 100%;
  margin: 12rem 0 0 0;
  -webkit-box-ordinal-group: 3;
          order: 2; }
  article > div {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: left;
            justify-content: left;
    -webkit-box-align: center;
            align-items: center;
    margin-bottom: 2rem; }
    article > div div {
      width: 7rem;
      height: 7rem;
      margin: 0 1rem 0 0;
      padding-left: 2px;
      border-radius: 18px;
      background-color: #14181e;
      line-height: 7rem;
      text-align: center;
      font-size: 1.4rem;
      color: white; }

.wrapper {
  -webkit-perspective: 1500px;
          perspective: 1500px;
  width: 100%;
  -webkit-box-ordinal-group: 2;
          order: 1; }

.header {
  width: 100%;
  max-width: 60rem;
  margin: 0 auto 7rem auto;
  text-align: center; }
  .header h4:last-of-type {
    margin-top: 2rem;
    font-size: 2rem;
    color: #5b6c8c; }

@media screen and (min-width: 768px) {
  section {
    padding: 4rem 4rem 0 4rem; }
  article {
    width: 50%;
    margin: auto 0;
    padding-left: 7rem;
    -webkit-box-ordinal-group: 2;
            order: 1; }
  .wrapper {
    width: 50%;
    -webkit-box-ordinal-group: 3;
            order: 2; }
  .header {
    margin: 11rem auto 7rem auto; } }
/*# sourceMappingURL=src/components/sections/ecommerce/ecommerce.svelte.map */</style>

<section use:intersectionListener>

    <ContentWrapper>
        {#if intersecting}
            <article>
                <div transition:fly="{trasn1}">
                    <div>DevSonic</div>
                    <h3>{labels.ecommerce.name}</h3>
                </div>

                <p transition:fly="{trans2}">{labels.ecommerce.caption2}</p>
                <p transition:fly="{trans2}">{labels.ecommerce.caption}<p>
            </article>

            <div class="wrapper">
                <MockupWeb intersecting/>
            </div>
        {/if}
    </ContentWrapper>


    <ContentWrapper disableFlex disableMarginTop smallMarginBottom>
        <div class="header">
            <h4>{labels.ecommerce.title}</h4>
            <h4>{labels.ecommerce.subtitle}</h4>
        </div>

        <MockupDashboard/>
    </ContentWrapper>

</section>