<style lang="scss">.wrapper {
  position: relative;
  width: 100%;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
          justify-content: center; }

.card, .chart, .errors {
  width: 75%;
  height: 20.5rem;
  border-radius: 10px;
  border: 1px solid #edeff2;
  background-color: white;
  -webkit-transition: -webkit-transform 1s;
  transition: -webkit-transform 1s;
  transition: transform 1s;
  transition: transform 1s, -webkit-transform 1s;
  box-shadow: #e5ecf9 0 5px 20px 0;
  overflow: hidden; }

.chart {
  position: absolute;
  padding: 2rem;
  -webkit-transform: translate(-3rem, -5rem);
          transform: translate(-3rem, -5rem);
  z-index: 20; }
  .chart h6 {
    margin-top: 0; }

.errors {
  -webkit-transform: translate(2rem, 5rem);
          transform: translate(2rem, 5rem); }
  .errors ul {
    width: 100%;
    height: 100%; }
  .errors li {
    width: 100%;
    height: 25%;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
            align-items: center;
    padding: 0 2rem; }
    .errors li:nth-child(even) {
      background-color: #f7f9ff; }

.graph {
  height: calc(100% - 4rem);
  background-image: url("/assets/images/chart.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain; }

.event {
  width: 150px;
  margin-right: 2rem; }
  .event span {
    display: inline-block;
    margin-bottom: 2px;
    padding: 2px 5px;
    border-radius: 20px;
    background-color: #edeff2;
    font-size: 10px;
    font-weight: 500;
    color: #5b6c8c; }
  .event div {
    font-size: 12px; }

.count {
  text-align: center; }
  .count div:first-child {
    margin-bottom: 2px; }
  .count div:nth-child(2) {
    font-size: 12px; }

@media screen and (min-width: 768px) {
  .card, .chart, .errors {
    height: 24.5rem; }
  .chart {
    -webkit-transform: translate(0, -5rem);
            transform: translate(0, -5rem); }
  .errors {
    -webkit-transform: translate(5rem, 8rem);
            transform: translate(5rem, 8rem); }
    .errors li {
      width: 100%;
      display: -webkit-box;
      display: flex;
      padding: 1.4rem 2rem; }
  .event {
    margin-right: 9rem; } }
/*# sourceMappingURL=src/components/UI/mockupCards/mockupCards.svelte.map */</style>

<div class="wrapper">
    <div class="chart">
        <h6>Error Events</h6>
        <div class="graph"></div>
    </div>

    <div class="errors">
        <ul>
            <li>
                <div class="event">
                    <span>ReferenceError</span>
                    <div>user is not defined</div>
                </div>

                <div class="count">
                    <div>25</div>
                    <div>events</div>
                </div>
            </li>

            <li>
                <div class="event">
                    <span>ReferenceError</span>
                    <div>user is not defined</div>
                </div>

                <div class="count">
                    <div>80</div>
                    <div>events</div>
                </div>
            </li>

            <li>
                <div class="event">
                    <span>TypeError</span>
                    <div>list.map is not a function</div>
                </div>

                <div class="count">
                    <div>47</div>
                    <div>events</div>
                </div>
            </li>

            <li>
                <div class="event">
                    <span>ReferenceError</span>
                    <div>user is not defined</div>
                </div>

                <div class="count">
                    <div>10</div>
                    <div>events</div>
                </div>
            </li>
        </ul>
    </div>
</div>