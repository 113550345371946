<script>
    import { fly } from "svelte/transition";
    import ContentWrapper from "../../layout/componentWrapper/componentWrapper.svelte";
    import MockupCard from "../../UI/mockupCards/mockupCards.svelte";

    import createIntersectionObserver from "helpers/intersectionObserver";
    import { labels } from "content";

    let intersecting;

    const intersectionListener = createIntersectionObserver(() => intersecting = true, "-150px");

    // Transition
    const trans1 = { y: 70, duration: 750, delay: 250 };
    const trans2 = { y: 70, duration: 1000, delay: 350 };
    const trans3 = { y: 70, duration: 1100, delay: 400 };
</script>

<style lang="scss">section {
  padding: 7rem 2rem 0 2rem; }

article {
  width: 100%;
  margin: 12rem 0 0 0;
  -webkit-box-ordinal-group: 3;
          order: 2; }
  article > div {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: left;
            justify-content: left;
    -webkit-box-align: center;
            align-items: center;
    margin-bottom: 2rem; }
    article > div div {
      width: 7rem;
      height: 7rem;
      display: -webkit-box;
      display: flex;
      -webkit-box-pack: center;
              justify-content: center;
      -webkit-box-align: center;
              align-items: center;
      margin: 0 1rem 0 0;
      padding-left: 2px;
      border-radius: 18px;
      background-color: #14181e;
      color: white;
      text-align: center; }
      article > div div img {
        width: 55%;
        margin-left: 0.5rem; }

.wrapper {
  width: 100%;
  -webkit-box-ordinal-group: 2;
          order: 1; }

@media screen and (min-width: 768px) {
  section {
    padding: 9rem 4rem; }
  article {
    width: 50%;
    margin: auto 0;
    padding-left: 7rem;
    -webkit-box-ordinal-group: 2;
            order: 1; }
  .wrapper {
    width: 50%;
    -webkit-box-ordinal-group: 3;
            order: 2; } }
/*# sourceMappingURL=src/components/sections/support/support.svelte.map */</style>

<section use:intersectionListener>
    <ContentWrapper smallMarginBottom>
        {#if intersecting}
            <article>
                <div transition:fly="{trans1}">
                    <div>Support</div>
                    <h3>{labels.support.name}</h3>
                </div>
                <p transition:fly="{trans2}">{labels.support.caption}</p>
            </article>

            <div class="wrapper" transition:fly="{trans3}">
                <MockupCard/>
            </div>
        {/if}
    </ContentWrapper>
</section>