<style lang="scss">.nav {
  width: 100%;
  height: 15%;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
          align-items: center;
  padding: 0 20px;
  background-color: white;
  backdrop-filter: saturate(180%) blur(20px);
  -webkit-backdrop-filter: saturate(180%) blur(20px); }
  .nav > div {
    height: 50%; }

.burger {
  width: 25px;
  height: 10px; }
  .burger div:first-child {
    width: 25px;
    height: 2px;
    margin-bottom: 7px;
    background-color: #14181e; }
  .burger div:last-child {
    width: 20px;
    height: 2px;
    margin-left: 5px;
    background-color: #14181e; }
/*# sourceMappingURL=src/components/UI/mockupMobile/navbar/navbar.svelte.map */</style>

<div class="nav">
    <div class="burger">
        <div></div>
        <div></div>
    </div>
</div>