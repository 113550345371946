<script>
    import { fly } from "svelte/transition";

    import ContentWrapper from "../../layout/componentWrapper/componentWrapper.svelte";

    import newIntersectionObserver from "helpers/intersectionObserver";
    import { labels } from "content";

    let intersecting;

    const intersectionListener = newIntersectionObserver(() => intersecting = true, "-150px");

   
    // Transitions
    const t1 = { y: 70, duration: 750, delay: 250 };
    const t2 = { y: 70, duration: 1000, delay: 350 };
    const t3 = { y: 70, duration: 1100, delay: 400 };
    const t4 = { y: 70, duration: 1250, delay: 350 };
    const t5 = { x: 400, duration: 1000, delay: 250 };
</script>

<style lang="scss">article {
  width: 100%;
  margin: 5rem 0 0 0;
  -webkit-box-ordinal-group: 3;
          order: 2; }
  article h4 {
    margin-bottom: 2rem; }
  article img {
    width: 7rem;
    height: 7rem;
    margin: 0 1rem 0 0; }
  article div {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: left;
            justify-content: left;
    -webkit-box-align: center;
            align-items: center;
    margin-bottom: 1rem; }

.showcase {
  width: 100%;
  -webkit-box-ordinal-group: 2;
          order: 1; }
  .showcase img {
    width: 100%;
    height: auto;
    margin: 0 auto; }

@media screen and (min-width: 768px) {
  article {
    width: 50%;
    margin: auto 4rem auto 0;
    -webkit-box-ordinal-group: 2;
            order: 1; }
  .showcase {
    width: 50%;
    -webkit-box-ordinal-group: 3;
            order: 2; } }
/*# sourceMappingURL=src/components/sections/webdev/webdev.svelte.map */</style>

<section use:intersectionListener>
    <ContentWrapper>
        {#if intersecting}
            <article>
                <div transition:fly="{t1}">
                    <h3>{labels.webdev.name}</h3>
                </div>
                <p transition:fly="{t2}">{labels.webdev.caption}</p>
                <p transition:fly="{t3}">{labels.webdev.caption2}</p>
            </article>
            <div class="showcase">
                <img src="/assets/images/feature_mobile_2-2.png" alt="showcase" transition:fly="{t5}"/>
            </div>
        {/if}
    </ContentWrapper>
</section>