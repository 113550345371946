<style lang="scss">img {
  width: auto;
  height: 4rem;
  margin-right: 1rem;
  -webkit-filter: brightness(0) invert(1);
          filter: brightness(0) invert(1);
  cursor: pointer; }
/*# sourceMappingURL=src/components/UI/socialButtons/socialButtons.svelte.map */</style>

<div>
    <a href="mailto:devsonic2020@gmail.com" target="_top">
        <img src="/assets/socialButtons/email.svg" alt="email"/>
    </a>

    <a href="https://www.facebook.com/DevSonic2020" target="_blank">
        <img src="/assets/socialButtons/facebook-app-logo.svg" alt="facebook"/>
    </a>

</div>