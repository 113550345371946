<script>
    import ContentWrapper from "components/layout/componentWrapper/componentWrapper.svelte";
    import SocialButtons from "components/UI/socialButtons/socialButtons.svelte";

    import { labels } from "content";
    
</script>

<style lang="scss">article {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  -webkit-box-pack: center;
          justify-content: center;
  padding: 4rem;
  -webkit-box-ordinal-group: 2;
          order: 1; }

h5 {
  margin-bottom: 3rem;
  color: white; }

.banner {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  -webkit-box-pack: justify;
          justify-content: space-between;
  -webkit-box-align: end;
          align-items: flex-end;
  border-radius: 10px;
  background-color: #14181e;
  background-image: radial-gradient(circle at bottom left, #292d33, #14181e);
  box-shadow: 0 5px 8px rgba(0, 30, 85, 0.12), 0 1px 3px rgba(0, 30, 85, 0.08);
  color: white;
  overflow: hidden; }

.image {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: end;
          align-items: flex-end;
  -webkit-box-ordinal-group: 3;
          order: 2; }
  .image img {
    width: 100%;
    height: auto; }

@media screen and (min-width: 576px) {
  article {
    width: 55%;
    -webkit-box-ordinal-group: 3;
            order: 2; }
  .banner {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    background-image: radial-gradient(circle at top left, #292d33, #14181e); }
  .image {
    width: 45%;
    -webkit-box-ordinal-group: 2;
            order: 1; }
    .image img {
      width: 100%;
      height: auto;
      margin-left: 5%; } }

@media screen and (min-width: 992px) {
  .banner {
    height: 35rem; }
  .image img {
    width: auto;
    height: 95%; } }
/*# sourceMappingURL=src/components/sections/banner/banner.svelte.map */</style>

<section>
    <ContentWrapper smallMarginBottom>
        <div class="banner">
            <div class="image">
                <img src="/assets/contactUs/contactus.png" alt="contact-us"/>
            </div>

            <article>
                <h2>{labels.banner.title}</h2>
                <h5>{labels.banner.subtitle}</h5>
                <SocialButtons/>
            </article>
        </div>
    </ContentWrapper>
</section>