<script>
    import { fly } from "svelte/transition";

    import ContentWrapper from "../../layout/componentWrapper/componentWrapper.svelte";

    import newIntersectionObserver from "helpers/intersectionObserver";
    import { labels } from "content";

    let intersecting;

    const intersectionListener = newIntersectionObserver(() => intersecting = true, "-150px");

    // Svelte Transitions Params
    const trans1 = { y: 200, duration: 750, delay: 250 };
</script>

<style lang="scss">section {
  overflow-x: visible; }

article {
  width: 100%;
  height: 100%;
  margin: 1.5rem 0 0 0;
  -webkit-box-ordinal-group: 3;
          order: 2; }
  article p {
    margin-bottom: 2rem; }

.bubbles {
  width: 50%;
  height: 100%;
  display: none;
  -webkit-box-ordinal-group: 3;
          order: 2; }

.bubble, .laravel, .java, .mysql, .spring {
  width: 10rem;
  height: 10rem;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
          justify-content: center;
  -webkit-box-align: center;
          align-items: center;
  flex-shrink: 0;
  border-radius: 50%;
  background-color: white;
  -webkit-animation: float 1.8s alternate ease-in-out infinite;
          animation: float 1.8s alternate ease-in-out infinite;
  overflow: hidden; }
  .bubble img, .laravel img, .java img, .mysql img, .spring img {
    width: 80%;
    height: auto; }

.laravel {
  width: 10.5rem;
  height: 10.5rem;
  margin: 0 0 0 29rem;
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s; }

.java {
  width: 12rem;
  height: 12rem;
  margin: 0 0 0 14rem;
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s; }
  .java img {
    margin-top: 0.8rem;
    width: 70%; }

.mysql {
  width: 11rem;
  height: 11rem;
  margin: 0 0 0 28rem;
  -webkit-animation-delay: 0.35s;
          animation-delay: 0.35s; }

.spring {
  margin: 0 0 0 11rem;
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s; }
  .spring img {
    width: 65%; }

.banner {
  width: 100%;
  height: 3.5rem;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
          align-items: center; }
  .banner img {
    width: auto;
    height: 80%; }

@media screen and (min-width: 768px) {
  article {
    width: 50%;
    margin: auto 4rem auto 5rem;
    -webkit-box-ordinal-group: 2;
            order: 1; }
  .bubbles {
    display: block; }
  .banner {
    display: none; } }
/*# sourceMappingURL=src/components/sections/backend/backend.svelte.map */</style>

<section use:intersectionListener>
    <ContentWrapper disableMarginTop smallMarginBottom>
        {#if intersecting}
            <article transition:fly="{trans1}">
                <h4>{labels.stack.backendTitle}</h4>

                <p>{labels.stack.backendText}</p>

                <div class="banner">
                    <img src="/assets/logos/laravel.svg" alt="laravel"/>
                    <img src="/assets/logos/java.svg"  alt="java"/>
                    <img src="assets/logos/mysql.svg" alt="mysql"/>
                    <img src="/assets/logos/springio.svg" alt="spring"/>
                </div>
            </article>

            <div class="bubbles" transition:fly="{trans1}">
                <div class="laravel">
                    <img src="/assets/logos/laravel.svg" alt="laravel"/>
                </div>

                <div class="java">
                    <img src="/assets/logos/java.svg" style="height:70px;" alt="java"/>
                </div>

                <div class="mysql">
                    <img src="assets/logos/mysql.svg" style="height:70px;" alt="mysql"/>
                </div>

                <div class="spring">
                    <img src="/assets/logos/springio.svg" style="height:70px;" alt="spring"/>
                </div>
            </div>
        {/if}
    </ContentWrapper>
</section>