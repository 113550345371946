<script>
    import { fly } from "svelte/transition";

    // Transitions
    const t1 = { y: 100, duration: 1500, delay: 1000 };
</script>

<style lang="scss">.card {
  width: 100%;
  height: 140px;
  margin-bottom: 15px;
  padding: 15px;
  border-radius: 10px;
  background: url(/assets/images/mesh.jpg);
  background-size: cover;
  box-shadow: 0 5px 8px rgba(0, 30, 85, 0.12), 0 1px 3px rgba(0, 30, 85, 0.08);
  font-size: 1.2rem;
  color: #414141; }
  .card div:nth-child(2) {
    margin-top: 55px;
    font-family: 'Share Tech Mono', monospace;
    letter-spacing: 1px;
    font-size: 1.7rem;
    font-weight: bold; }
  .card div:last-child {
    font-size: 1.2rem; }
/*# sourceMappingURL=src/components/UI/mockupMobile/card/card.svelte.map */</style>

<div class="card" transition:fly="{t1}">
    <div><b>DevSonic</b> | Payment</div>
    <div>5003 2019 2091 2901</div>
    <div><span>Mr. Kudzai Mabika</span></div>
</div>