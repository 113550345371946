<script>
    export let disableMarginTop = false;
    export let smallMarginBottom = false;
    export let disableFlex = false;


    const display = `display: ${disableFlex ? "block" : "flex"};`;
    //Smaller bottom & top margin when the user changes devices.
    const topMargin = disableMarginTop ? "margin-top: 0;" : "margin-top: 8rem;";
    const bottomMargin = smallMarginBottom ? "margin-bottom: 0;" : "margin-bottom: 8rem;";

    const style = display + topMargin + bottomMargin;
</script>

<style lang="scss">.wrapper {
  position: relative;
  width: 100%;
  max-width: 1250px;
  min-height: 13rem;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  -webkit-box-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
          align-items: center; }
  @media screen and (min-width: 768px) {
    .wrapper {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
              flex-direction: row;
      -webkit-box-align: normal;
              align-items: normal; } }
/*# sourceMappingURL=src/components/layout/componentWrapper/componentWrapper.svelte.map */</style>

<div class="wrapper" style="{style}">
    <slot/>
</div>