<script>
    export let bottomHeight;
    export let caption = "week";
</script>

<style lang="scss">.wrapper {
  width: 4.5rem;
  height: 100%;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  -webkit-box-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
          align-items: center; }
  .wrapper:last-of-type {
    display: none; }

.bar {
  height: calc(100% - 2.5rem);
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  -webkit-box-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
          align-items: center; }
  .bar div {
    width: 1.5rem;
    border-radius: 25px;
    -webkit-transition: height 1.5s;
    transition: height 1.5s;
    will-change: height; }
  .bar div:nth-child(1) {
    -webkit-box-flex: 1;
            flex-grow: 1;
    background-color: #edeff2; }
  .bar div:nth-child(2) {
    margin-top: 0.5rem;
    background-color: #414141; }

.caption {
  width: 100%;
  height: 1.5rem;
  font-size: 1.1rem;
  font-weight: bold;
  text-align: center; }

@media screen and (min-width: 768px) {
  .wrapper:last-of-type {
    display: -webkit-box;
    display: flex; } }
/*# sourceMappingURL=src/components/UI/mockupDashboard/statistic/bar.svelte.map */</style>

<div class="wrapper">

    <div class="bar">
        <div></div>
        <div style="height: {bottomHeight}%;"></div>
    </div>

    <div class="caption">{caption}</div>
</div>
