<script>
    export let title = "";
    export let subtitle = "";
</script>

<style>
    section {
        margin-top: 10rem;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
                flex-direction: column;
        text-align: center;
    }

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL2xheW91dC90aXRsZS90aXRsZS5zdmVsdGUiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IjtJQUNJO1FBQ0ksaUJBQWlCO1FBQ2pCLDRCQUFzQjtRQUF0Qiw2QkFBc0I7Z0JBQXRCLHNCQUFzQjtRQUN0QixrQkFBa0I7SUFDdEIiLCJmaWxlIjoic3JjL2NvbXBvbmVudHMvbGF5b3V0L3RpdGxlL3RpdGxlLnN2ZWx0ZSIsInNvdXJjZXNDb250ZW50IjpbIlxuICAgIHNlY3Rpb24ge1xuICAgICAgICBtYXJnaW4tdG9wOiAxMHJlbTtcbiAgICAgICAgZmxleC1kaXJlY3Rpb246IGNvbHVtbjtcbiAgICAgICAgdGV4dC1hbGlnbjogY2VudGVyO1xuICAgIH1cbiJdfQ== */</style>

<section>
    <h3>{title}</h3>
    <h5>{subtitle}</h5>
</section>