<script>
    import Integrations from "./integrations/integrations.svelte";
    import Statistic from "./statistic/statistic.svelte";

    import { labels } from "content";
</script>

<style lang="scss">.mockup {
  width: 100%;
  height: 44.5rem;
  margin: 0 auto;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  box-shadow: 0 0 4rem 0 rgba(50, 50, 95, 0.5);
  overflow: hidden; }

.browser {
  position: relative;
  width: 100%;
  height: 5.1rem;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  background: -webkit-gradient(linear, left bottom, left top, color-stop(20%, #383a3c), to(#444649));
  background: linear-gradient(0deg, #383a3c 20%, #444649 100%); }

.controls {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center; }
  .controls div {
    width: 1.4rem;
    height: 1.4rem;
    margin-left: 1.5rem;
    border-radius: 50%;
    background-color: #ff463a; }
    .controls div:nth-child(2) {
      background-color: #ffd70a; }
    .controls div:nth-child(3) {
      background-color: #32d64b; }

.bar {
  width: 60%;
  height: 55%;
  margin-left: 11rem;
  border-radius: 40px;
  background-color: #585b60; }

.nav {
  width: 100%;
  height: 5rem;
  line-height: 5rem;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
          justify-content: space-between;
  padding: 0 1.5rem;
  border-bottom: 1px solid #edeff2; }
  .nav > div {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
            align-items: center; }
    .nav > div div {
      width: 7rem;
      height: 1.5rem;
      border-radius: 2rem;
      background-color: #edeff2; }
      .nav > div div:last-child {
        width: 4rem;
        height: 4rem;
        margin-left: 1rem;
        border-radius: 50%; }

.content {
  width: 100%;
  max-width: 600px;
  height: calc(100% - 10.1rem);
  margin: 0 auto;
  padding: 1.5rem; }

.title {
  display: block;
  margin-bottom: 1rem;
  font-size: 1.8rem;
  font-weight: bold; }

@media screen and (min-width: 768px) {
  .mockup {
    width: 85%; }
  .nav {
    padding: 0 2rem; }
  .content {
    padding: 2rem; } }
/*# sourceMappingURL=src/components/UI/mockupDashboard/mockupDashboard.svelte.map */</style>

<div class="mockup">
    <div class="browser">
        <div class="controls">
            <div></div>
            <div></div>
            <div></div>
        </div>

        <div class="bar"></div>
    </div>

    <div class="nav">
        <b>{labels.ecommerce.onlineStore}</b>

        <div>
            <div></div>
            <div></div>
        </div>
    </div>

    <div class="content">
        <span class="title">{labels.ecommerce.chart}</span>

        <Statistic/>

        <span class="title">{labels.ecommerce.latest}</span>

        <Integrations/>
    </div>
</div>