<script>
    import ComponentWrapper from "../componentWrapper/componentWrapper.svelte";
    import SocialButtons from "components/UI/socialButtons/socialButtons.svelte";

    import {labels} from "content";

</script>

<style lang="scss">footer {
  margin-top: 10rem;
  padding: 10rem 2rem 0 2rem;
  background-color: #14181e;
  color: white; }

.logo {
  height: 2rem; }

article {
  width: 100%;
  margin-bottom: 5rem;
  text-align: center; }
  article:first-child {
    font-size: 3rem; }
  article:nth-child(3) li {
    cursor: pointer; }
    article:nth-child(3) li:hover {
      font-weight: 500; }

a {
  color: white; }

b {
  display: inline-block;
  margin-bottom: 2.5rem; }

li {
  margin-bottom: 1rem; }

@media screen and (min-width: 768px) {
  footer {
    padding: 10rem 4rem 0 4rem; }
  article {
    width: calc(65% / 3);
    text-align: left; }
    article:first-child {
      width: 35%; } }
/*# sourceMappingURL=src/components/layout/footer/footer.svelte.map */</style>

<footer class="top-clip">
    <ComponentWrapper>
        <article>
            <span>
                <img  class ="logo" src="/assets/images/devsonic-logo.png" alt="logo">
                <b>DevSonic</b>
            </span>
        </article>

        <article>
            <ul>
                <li>{labels.footer.name}</li>
                <li>{labels.footer.copyright}</li>
            </ul>
        </article>

        <article>
            <b>{labels.footer.contact}</b>
            <ul>
                <li>
                    <a href="mailto:devsonic2020@gmail.com" target="_top">{labels.footer.email}</a>
                </li>
                <li>
                    Call : +27 73 899 2160
                </li>
                <li>
                    Call : +27 63 114 8022
                </li>
            </ul>
        </article>

        <article>
            <b>{labels.footer.connect}</b>
            <SocialButtons/>
        </article>
    </ComponentWrapper>
</footer>